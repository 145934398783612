<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-locale-provider>
</template>
<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import enUs from 'ant-design-vue/lib/locale-provider/en_US'
  import enquireScreen from '@/utils/device'
  import { getLanguage } from './store/mutation-types'


  export default {
    data () {
      return {
        locale: enUs,
      }
    },
    created () {
      let that = this
      enquireScreen(deviceType => {
        // tablet
        if (deviceType === 0) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        // mobile
        else if (deviceType === 1) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        else {
          that.$store.commit('TOGGLE_DEVICE', 'desktop')
          that.$store.dispatch('setSidebar', true)
        }
      })
      this.loadLocale();
    },
    mounted() {
      // this.$nextTick(() => {
      //   let logo = document.getElementsByClassName('logo')[0];
      //   if(logo){
      //     console.log("logo",logo);
      //     logo.style.width = "177px"
      //     logo.style.height = "177px"
      //     logo.style.marginTop = "0px"
      //     logo.style.marginRight = "0"
      //   }
      //   let title = document.getElementsByClassName('desc')[0];
      //   if(title){
      //     title.style.marginTop = "123px"
      //     title.style.fontSize = "20px"
      //     // title.style.color = "#e69d2d"
      //     title.style.color = "rgba(0, 0, 0, 0.45);"
      //   }
      //
      //
      //   let menuTitle = document.getElementsByClassName('ant-menu-submenu-title')[0];
      //   console.log("menuTitle",menuTitle)
      // })

    },
    methods:{
      loadLocale(){
        switch (getLanguage()){
        case "zh":
          this.locale = zhCN;
          break;
        case "en":
          this.locale = enUs;
          break;
        }
      }
    }
  }
</script>
<style lang='less'>
  #app {
    height: 100%;
  }
  // 覆盖menu样式
  .ant-menu.ant-menu-root {
    & > .ant-menu-item:first-child {


      & > a, & > a:hover {
        //color: rgba(0, 0, 0, 0.65);
      }

      &.ant-menu-item-selected {
        & > a, & > a:hover {
          color: @primary-color;
        }
      }
    }
    //
    &.ant-menu-dark > .ant-menu-item:first-child {
      & > a, & > a:hover {
        color: #000000 !important;
      }

      //&.ant-menu-item-selected {
      //  & > a, & > a:hover {
      //    color: #000000 !important;
      //  }
      //}
    }
  }
  .ant-menu-item{
    transition: all ease 1s;
  }
  .ant-menu-item-selected{
  }
  .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
    color: white !important;
  }
  .ant-menu.ant-menu-root>.ant-menu-item-selected:first-child{
    background-color: @primary-color !important;
  }
  .top{
    .header{
      height: 160px !important;
    }
    .desc{
      font-size: 20px !important;
      margin-bottom: 20px !important;
    }
  }

</style>
