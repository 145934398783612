export default {
  logout: '退出登录',
  language: '语言',
  error: '错误',
  alert: '提示',
  logoutAlert: '确定要注销登录吗 ?',
  loadingHint: '加载中...',
  searchMenu: '搜索菜单',
  welcome: '欢迎',
  seeMore: '查看更多',
  publish: '发布',
  notification: '通知',
  announcement: '公告',
  normal: '一般',
  important: '重要',
  urgent: '紧急',
  viewDetail: '查看详情',
  notifications: '通知消息',
  title: '标题',
  fieldEmptyHint: '该字段不能为空',
  IncorrectValue: '格式不对',
  messageType: '消息类型',
  publisher: '发布人',
  publishDate: '发布时间',
  priority: '优先级',
  search: '查询',
  reset: '重置',
  readAll: '全部已读',
  readStatus: '阅读状态',
  view: '查看',
  notificationManagementPage: '系统通知管理页面',
  high: '高',
  medium: '中',
  low: '低',
  haveRead: '已读',
  unread: '未读',
  action: '操作',
  actions: '操作',
  operationConfirm: '确认操作',
  allMarkedRead: '全部标记已读',
  login: '登录',
  loginFailed: '登录失败',
  delete: '删除',
  lock: '锁定',
  create: '新建',
  username: '帐号',
  password: '登录密码',
  youHaveNotBelongedToTheDepartmentHint: '您尚未归属部门,请确认账号信息',
  incorrectVerificationCode: '验证码不正确',
  verificationCodeIsBeingSent: '验证码正在发送',
  age: '年龄',
  pleaseInputPhoneNumber: '请输入手机号码',
  changeAvatar: '修改头像',
  cancel: '取消',
  sureToCancel: '确认取消？',
  sureToDelete: '确认删除？',
  save: '保存',
  name: '名称',
  loginPassword: '登录密码',
  email: '邮箱',
  batchDelete: '批量删除',
  selected: '已选择',
  clean: '清除',
  edit: '编辑',
  more: '更多',
  detail: '详情',
  addSubordinate: '添加下级',
  dataRule: '数据规则',
  confirmDeleteAlert: '确定要删除吗？',
  menuName: '菜单名称',
  menuType: '菜单类型',
  component: '组件',
  path: '路径',
  sort: '排序',
  close: '关闭',
  confirmPassword: '确认密码',
  userInfo: '用户信息',
  department: '部门',
  gender: '性别',
  mobilePhoneNumber: '手机号码',
  confirmUnlinkHint: '确认取消关联？',
  selectDepartmentAlert: '请选择一个部门',
  addExistedUser: '添加已存在用户',
  address: '详细地址',
  userList: '用户列表',
  male: '男',
  female: '女',
  total: '全部',
  selectUserByDepartment: '用户选择器',
  workNo: '工号',
  saveAndClose: '保存并关闭',
  fileUpload: '文件上传',
  warning: '警告',
  dataLogWindow: '数据对比窗口',
  unlink: '取消关联',
  unlinkUserWithDepartmentAlert: '确定取消与选中部门关联吗?',
  list: '列表',
  modify: '修改',
  import: '导入',
  export: '导出',
  enable: '启用',
  disable: '禁用',
  advanceSearch: '高级查询',
  rememberMe: '记住密码',
  item: '项',
  collapse: '收起',
  expand: '展开',
  batchOperation: '批量操作',
  relatedPerson: '知会人',
  deadline: '截止日期',
  startTime: '开始时间',
  startDate: '开始日期',
  endTime: '结束时间',
  endDate: '结束日期',
  noPicture: '没有图片',
  noFile: '没有文件',
  treeOperation: '树操作',
  groupId: '分组Id',
  status: '状态',
  owner: '负责人',
  position: '职位',
  teamworkTask: 'Teamwork任务',
  comment: '备注',
  datetime: '日期时间',
  node: '节点',
  task: '任务',
  panelName: '板块名称',
  teamworkPanel: '项目',
  teamworkGroup: '分组',
  accept: '接受',
  reject: ' 拒绝',
  refuse: '拒绝',
  confirm: '确认',
  notes: '备注',
  comments: '消息记录',
  attachments: '附件',
  pleaseSelectHint: '请选择',
  selectUser: '选择用户',
  add: '添加',
  block: '阻塞',
  assign: '指派',
  inputContentHint: '请输入内容',
  selectFileHint: '请选择文件',
  submit: '提交',
  complete: '完成',
  operationHistory: '操作历史',
  createTask: '创建任务',
  editTask: '编辑任务',
  createGroup: '创建分组',
  editGroup: '编辑分组',
  mobileNumberAlreadyExists: '手机号码已存在',
  emailAlreadyExists: '邮箱已存在',
  usernameAlreadyExists: '用户名已存在',
  workNoAlreadyExists: '工号已存在',
  dingtalkUserIdAlreadyExists: '钉钉用户ID已存在',
  pleaseUploadImage: '请上传图片',
  changeView: '切换视图',
  viewAll: '查看所有',
  groupView: '分组视图',
  panelView: '个人视图',
  taskView: '分类视图',
  demand: '要求',
  description: '描述',
  positionName: '岗位名称',
  positionQuota: '编制',
  help: '帮助',
  positionAdvertise: '在招数量',
  minSalary: '最低薪资',
  maxSalary: '最高薪资',
  serviceEmployee: '在职员工',
  order: '排序',
  companyName: '公司名称',
  bu: 'Bu',
  jobResponsibilities: '岗位职责',
  jobRequirements: '任职要求',
  annualSalaryRange: '年薪范围',
  saveDraft: '保存草稿',
  createTime: '创建时间',
  createBy: '创建人',
  contractType: '合同类型',
  administrativeSuperior: '行政上级',
  administration: '行政',
  contractPeriod: '合同期限（月）',
  company: '公司',
  mentor: '导师',
  site: '地点',
  functionalSuperior: '职能上级',
  related: '相关人',
  reportDate: '报道日期',
  probationarySalary: '试用期薪资',
  probationaryTime: '试用期（月）',
  regularSalary: '转正后薪资',
  taskSubdivide: '分解',
  currentProcessor: '当前处理人',
  queryByStatus: '根据状态查询',
  businessUnit: '事业单位',
  graduationDate: '毕业日期',
  idType: '证件类型',
  idCard: '身份证号码',
  offer: 'Offer',
  currentProcessIssues: '当前处理事项',
  graduatedSchool: '毕业院校',
  politicalStatus: '政治面貌',
  maritalStatus: '婚姻状态',
  discipline: '专业',
  birthday: '出生日期',
  birthplace: '出生地',
  accountBank: '开户行',
  bankAccountNumber: '银行帐号',
  applicant: '申请人',
  currency: '货币',
  amount: '金额',
  amountBig: '金额大写',
  itemDetail: '明细',
  itemCategory: '类别',
  reimbursementDetail: '报销明细',
  print: '打印',
  amountTotal: '总计',
  amountTotalBig: '总计大写',
  applicationDate: '申请时间',
  serialNumber: '序列号',
  signatory: '权签人',
  Cashier: '出纳',
  financialOfficer: '财务人员',
  personnel: '出差人员',
  location: '考勤地点',
  cause: '出差事由',
  trafficType: '交通类型',
  trafficRemark: '交通备注',
  accommodationType: '住宿类型',
  accommodationRemark: '住宿备注',
  schedule: '行程安排',
  loanDemand: '借款需求',
  useing: '借款用途',
  receivingUnit: '收款单位',
  paymentUnit: '付款单位',
  procurementUnit: '采购单位',
  receive: '接收',
  reason: '原因',
  beginTime: '开始时间',
  hospitalityUnit: '招待单位',
  attendees: '列席人员',
  emergencyContactName: '紧急联系人',
  emergencyContactNumber: '紧急联系人号码',
  education: '学历',
  teamworkCategory: '分类',
  selectStartDateHint: '请选择开始时间',
  selectEndDateHint: '请选择结束时间',
  attendanceHolidayConfig: '节假日配置',
  attendanceTimeConfig: '排班配置',
  attendanceType: '考勤类型',
  historyRecords: '历史记录',
  attendanceAbnormal: '考勤异常',
  attendanceDate: '考勤日期',
  applicationTimesOfMonth: '本月此类申请',
  applicationUnit: '申请单位',
  applicationHistory: '申请记录',
  approvalHistory: '审批记录',
  allRecords: '所有记录',
  encrypt: '加密',
  dept: '部门',
  hr: '人事',
  yes: '是',
  no: '否',
  employingDepartment: '用人部门',
  category: '分类',
  borrowReason: '借款事由',
  procurementReason: '采购事由',
  ccToMe: '抄送给我',
  filter: '筛选',
  time: '时间',
  user: '用户',
  teamworkActivity: 'Teamwork活跃度统计',
  dataSheet: '数据表',
  dataLength: '数据长度',
  departmentSelect: '选择部门',
  applicationReason: '申请原因',
  applicationType: '申请类型',
  sealType: '印章类型',
  leaveType: '请假类型',
  date: '日期',
  noonAttendance: '中午考勤',
  morningWorkTime: '上午上班时间',
  MorningOffTime: '上午下班时间',
  AfternoonWorkTime: '下午上班时间',
  AfternoonOffTime: '下午下班时间',
  flexibleTime: '弹性工时',
  workTime: '工时',
  profile: '档案',
  earliestRecord: '最早记录',
  latestRecord: '最晚记录',
  attendanceId: '考勤id',
  attendanceTime: '考勤时间',
  processed: '已处理',
  synchronisedTime: '同步时间',
  basicInfo: '基本信息',
  departmentRole: '部门角色',
  invalidDepartInfo: '您的部门下暂无有效部门信息!',
  noPermissionEmployeeInfo: '普通员工暂此权限!',
  institutionName: '机构名称',
  higherOffice: '上级部门',
  institutionCode: '机构编码',
  institutionType: '机构类型',
  fax: '传真',
  mobile: '电话',
  departmentRoleName: '部门角色名称',
  departmentRoleCode: '部门角色编码',
  addDepRole: '新建部门角色',
  authorization: '授权',
  addDept: '添加部门',
  addAnother: '新增',
  selectSubDeptWarn: '请先点击选中上级部门!',
  successDelete: '删除成功',
  failDelete: '删除失败',
  selectRecordWarn: '请选择一条记录！',
  selectDeptWarn: '请先选择一个部门!',
  currentSelection: '当前选择',
  cancelSelection: '取消选择',
  fatherSonConnection: '父子关联',
  checkAll: '全部勾选',
  unselectAll: '取消全选',
  expandAll: '展开所有',
  mergeAll: '合并所有',
  deptAuthority: '部门权限',
  actualName: '姓名',
  userStatus: '用户状态',
  ordinary: '正常',
  freeze: '冻结',
  isFreeze: '确定冻结吗？',
  addUser: '新增用户',
  recycleBin: '回收站',
  roleAssignments: '角色分配',
  roleAssignmentsDept: '部门角色分配',
  deptAllocation: '部门分配',
  avatar: '头像',
  upload: '上传',
  select: '选择',
  jobTitle: '职务名称',
  job: '职务',
  landline: '座机',
  dingtalkUserId: '钉钉用户ID',
  obtain: '获取',
  unFindDingTalkIdInfo: '查询不到该手机号码的钉钉用户ID',
  giveUpEditInfo: '确定放弃编辑吗？',
  agent: '代理人',
  unfreeze: '解冻',
  resetPwd: '重新设定密码',
  inputPwdInfo: '密码由8位数字、大小写字母和特殊符号组成!',
  userAgentSetting: '用户代理人设置',
  agentUserName: '代理人用户名',
  agentStartTime: '代理开始时间',
  agentEndTime: '代理结束时间',
  valid: '有效',
  invalid: '无效',
  responsibleDept: '负责部门',
  adminNotAllow: '管理员账号不允许此操作',
  recycleBinUser: '用户回收站',
  batchRestore: '批量还原',
  restoreUser: '还原用户',
  removeCompletely: '彻底删除',
  deleteWarning: '注意：彻底删除后将无法恢复，请谨慎操作！',
  entryDept: '入职部门',
  resume: '简历',
  certificateType: '证件类型',
  associateOffer: '关联offer',
  licenseNumber: '证件号',
  postalCode: '邮政编码',
  entryTime: '入职时间',
  nation: '民族',
  use: '用途',
  photo: '照片',
  currentType: '币种',
  reasonPayment: '付款原因',
  borrower: '借款单位',
  bankAccount: '开户银行',
  repaymentDate: '预计还款日',
  reimbursementUnit: '报销单位',
  deliveryDate: '期望交付日期',
  purchaseType: '采购类型',
  paymentMethod: '支付方式',
  businessReasons: '业务事由',
  moduleName: '模块名称',
  moduleKey: '模块Key',
  functionId: '功能',
  stateValue: '状态值',
  upperStateValue: '上级流程状态值',
  subStateJson: '下级动作和对应的状态JSON',
  approverUsername: '审批人用户名',
  alternateApprover: '备用审批人',
  nodeType: '节点类型',
  approverRules: '审批人规则',
  alternateApproverRules: '备用审批人规则',
  roleName: '角色名称',
  newRole: '新建角色',
  roleCode: '角色编码',
  existingUsers: '已有用户',
  addExistingUsers: '添加已有用户',
  selectRoleWarning: '请选择一个角色!',
  rolePermissionConfig: '角色权限配置',
  permissions: '所拥有的权限',
  primaryMenu: '一级菜单',
  submenu: '子菜单',
  btnOrPermission: '按钮/权限',
  module: '模块',
  menuPath: '菜单路径',
  menuComponent: '菜单组件',
  defaultJumpAddress: '默认跳转地址',
  authorizationId: '授权标识',
  authorizationStrategy: '授权策略',
  menuIcon: '菜单图标',
  whetherRouteMenu: '是否路由菜单',
  directionalIcon: '方向性图标',
  indicativeIcon: '指示性图标',
  editIcon: '编辑类图标',
  dataIcon: '数据类图标',
  websiteUniversalIcon: '网站通用图标',
  brandAndLogo: '品牌和标识',
  noIconSelected: '尚未选择任何图标',
  hiddenRoute: '隐藏路由',
  whetherCacheRoute: '是否缓存路由',
  aggregateRoute: '聚合路由',
  openMethod: '打开方式',
  external: '外部',
  internal: '内部',
  checkWarning: '请检查你填的类型以及信息是否正确',
  enterWarning: '请输入正整数!',
  existAuthoIdWarning: '授权标识已存在!',
  upperMenu: '上级菜单',
  seleceUpperMenuWarning: '请选择上级菜单',
  dataPermissionRule: '数据权限规则',
  ruleName: '规则名称',
  ruleValue: '规则值',
  ruleField: '规则字段',
  addSubmenu: '添加子菜单',
  conditionalRule: '条件规则',
  dictionaryName: '字典名称',
  dictionaryNumber: '字典编码',
  refreshCache: '刷新缓存',
  dictionaryConfig: '字典配置',
  dictionaryList: '字典列表',
  dictionaryRetrieval: '字典取回',
  dataValue: '数据值',
  sortValue: '排序值',
  sortValueInfo: '值越小越靠前，支持小数',
  whetherEnable: '是否启用',
  parentNode: '父级节点',
  sortCode: '分类编码',
  addSubcategory: '添加子分类',
  notificationType: '通告类型',
  designatedUser: '指定用户',
  allUsers: '全体用户',
  Summary: '摘要',
  content: '内容',
  calendar: '日历',
  announceNotify: '公告 / 通知',
  upcoming: '待办',
  accountSubjects: '会计科目',
  licenseCategory: '证照类别',
  useCategory: '使用类别',
  workCategory: '工种类型',
  accountCategory: '户口类型',
  countersigner: '会签人员',
  suggest: '建议',
  dateOfResignation: '预计离职时间',
  ethnic: '民族',
  contact: '联系方式',
  judges: '评委',
  result: '结果',
  averageScore: '平均分',
  introducer: '答辩人',
  qualified: '是否合格',
  defenseTime: '答辩时间',
  ictureGuid: '照片',
  leadSource: '线索来源',
  rcId: '线索来源ID',
  assistantName: '助手姓名',
  conEDate: '停用日期',
  rcType: '线索来源类型',
  approvedExpense: '批准费用',
  doNotCall: '请勿致电',
  expense: '发生费用',
  faxOptOut: '请勿发传真',
  conDepart: '部门',
  wechat: '微信号',
  assistantPhone: '助手电话',
  emailOptOut: '请勿发送邮件',
  code: '联系人编码',
  contactName: '联系人名称',
  cusCode: '所属客户',
  appellation: '称呼',
  sex: '性别',
  hometown: '籍贯',
  dutyId: '职务',
  superiorId: '直接上级',
  mobilePhone: '移动电话',
  officePhone: '办公电话',
  homePhone: '家庭电话',
  call: '传呼',
  homePage: '个人网页',
  workAddress: '工作地址',
  zipcode: '邮编',
  marriageId: '婚姻状况',
  homeMember: '家庭主要成员',
  homeAddress: '家庭地址',
  tasteId: '个人爱好',
  major: '是否主要联系人',
  memo: '备注',
  principal: '负责人',
  addBy: '创建人',
  addTime: '创建时间',
  updateBy: '更新人',
  updateTime: '更新时间',
  unit: '收货单位',
  linkPerson: '联系人',
  isDefault: '是否默认',
  englishAddress: '英文地址',
  englishAddress2: '英文地址',
  englishAddress3: '英文地址',
  englishAddress4: '英文地址',
  invoicecompany: '开票单位',
  primaryKeyStrategy: '主键策略',
  appraisalMonth: '考核月',
  typeName: '名称',
  tableId: '表单id',
  isPk: '是否为主键',
  cardNum: '身份证号码',
  phone: '联系电话',
  character: '性格',
  marriage: '婚姻状况',
  village: '村属',
  propertyType: '房屋性质',
  surveyedArea: '测绘面积(㎡)',
  surveyNumber: '房屋编号',
  surveyingStatus: '测量状态',
  landNumber: '所属地块',
  rightHolder: '权利人',
  buildingNumber: '栋号',
  totalFloors: '总楼层',
  structureType: '结构类型',
  ancestralHouse: '是否祖屋',
  realEstateLicenseType: '房产证类型',
  buildingId: '楼栋',
  floor: '楼层',
  roomNo: '房号',
  certificateConstructionArea: '产权证建筑面积(㎡)',
  increasedConstructionArea: '加建面积(㎡)',
  projectId: '项目',
  unitId: '单元',
  follower: '跟进人员',
  negotiationDifficultyLevel: '拆迁谈判难易等级',
  isEntrustedNotarization: '是否委托公证',
  isCancellationRealEstateLicense: '房产证是否已注销',
  deptId: '所属片区',
  area: '面积(㎡)',
  expend: '展开',
  selectStartTimeHint: '请选择开始时间',
  selectEndTimeHint: '请选择结束时间',
  projectItemId: '项目明细',
  ownerId: '业主',
  visitTime: '走访时间',
  visitStaff: '走访人员',
  visitTarget: '走访目的',
  carryGifts: '携带礼物',
  problem: '主要问题及要求',
  reply: '答复',
  instructions: '请示领导及指示',
  nextVisitTime: '下次走访时间',
  demolitionArea: '拆迁面积(㎡)',
  fetchableArea: '可回迁面积(㎡)',
  selectedRoomNo: '已选房号',
  actualFetchArea: '实际回迁面积(㎡)',
  areaCompensationAmount: '面积补差金额',
  areaCompensationPaymentStatus: '面积补差支付情况',
  relocationConfirmed: '回迁确认书',
  deleted: '逻辑删除',
  handoverTime: '房屋移交时间',
  contractedArea: '签约面积(㎡)',
  payee: '款项汇入人',
  openingBank: '开户行',
  versionId: '合同模板版本',
  entry: '条目',
  beforeSigning: '签约前',
  afterSigning: '签约后',
  afterFetching: '回迁后',
  checkBookData: {
    checking: '正在对账...',
    complete: '对账完成',
    preparing: '准备对账中...'
  },
  languageCode: {
    en: 'English',
    zh: '中文'
  },
  useDate: '使用日期',
  returnDate: '归还日期',
  type: '类型',
  isSideExpand: '是否侧边展开',
  workflowStatus: {
    0: '草稿',
    1: '指派',
    2: '进行中',
    3: '阻塞',
    4: '待审核',
    5: '完成',
    6: '未通过',
    7: '审批通过',
    8: '自评',
    9: '工作交接',
    10: 'IT确认',
    11: '行政确认',
    12: '人事确认',
    13: '财务确认',
    14: '已审核',
    15: '等待确认',
    16: '数据有误',
    17: '通过',
    18: '部分通过',
    35: '等待付款'
  },
  priorityCodes: {
    0: 'P0',
    1: 'P1',
    2: 'P2',
    3: 'P3'
  },
  actionCode: {
    assign: '指派',
    create: '创建',
    restore: '恢复',
    block: '阻塞',
    edit: '编辑',
    submit: '提交',
    copy: '复制',
    'review.refuse': '拒绝',
    'review.accept': '接受',
    'approval.reject': '审核不通过',
    'approval.agree': '审核通过',
    'conclusion.reject': '添加结论',
    'conclusion.agree': '添加结论',
    'last.agree': '同意',
    'last.disagree': '不同意',
    'disagree': '不同意',
    'agree': '同意',
    'score.agree': '审核通过',
    'score.return': '审核不通过',
    'markError': '标记错误',
    revocation: '撤销',
    confirm: '确认',
    cashier: '出纳',
    receive: '接收',
    delete: 'Delete',
    print: '打印',
    deliver: '交付',
    encrypt: '加密',
    decrypt: '解密',
    open: '开放自评',
    'review.pass': '评审通过',
    'review.notPass': '评审不通过',
    'review.partPass': '评审部分通过',
    'last.review.pass': '评审通过',
    'last.review.notPass': '评审不通过',
    'last.review.partPass': '评审部分通过',
    confirmationPayment: '等待付款',
    repossessionBuilding: '确认付款'
  },
  errorCode: {
    401: '未授权，请重新登录',
    403: '拒绝访问',
    404: '很抱歉，资源未找到!',
    500: '服务器内部错误',
    504: '网络超时',
    600: '参数有误',
    601: '参数值有误',
    700: '无处理权限',
    701: '创建失败',
    702: '更新失败',
    703: '删除失败',
    704: '处理失败',
    705: '请刷新后重试',
    706: '未找到对应数据',
    707: '该邮箱未注册',
    708: 'token失效',
    750: '字典值已存在',
    751: '组织架构已存在',
    752: '系统配置已存在',
    800: '未登录',
    801: '请输入二级密码',
    802: '二级密码有误',
    803: '请设置二级密码',
    804: '二级密码已存在',
    805: '登录密码有误',
    806: '两种密码不能相同',
    807: '密码规则不符合',
    808: '不能与旧密码相同',
    809: '二级密码不存在',
    810: '二级密码有误',
    900: '考勤排班配置有误',
    901: '请先完成评价',
    902: '调休工时不足',
    903: '不同排班不能同时提交',
    904: '考勤类型有误',
    5000: '工作流--未配置',
    5001: '工作流--配置有误',
    5002: '该动作已存在',
    5003: '请勿重复访问',
    5101: '相关人不能为自己',
    5102: '相关人不能为上级',
    5201: '未配置薪资查询字段组'
  },
  menus: {
    userCenter: '用户中心',
    accountSettings: '账户设置',
    sastemConfiguration: '系统设置',
    changePassword: '登录密码修改',
    changeSecondaryPassword: '二级密码修改',
    setSecondaryPassword: '二级密码设置',
    resetSecondaryPassword: '二级密码重置',
    menuManagementPage: '菜单管理',
    newMessages: '新消息',
    baseSettings: '基本设置',
    securitySettings: '安全设置'
  },
  totalScoreAdminSuperior: '行政上级评分',
  commentAdminSuperior: '行政上级评语',
  totalScoreCompetencySuperior: '职能上级评分',
  commentCompetencySuperior: '职能上级评语',
  totalScoreRelated: '相关人评分',
  commentRelated: '相关人评语',
  calcCategory: '计算类型',
  isBackfill: '是否回填',
  isEncrypt: '是否加密',
  backfillProfileField: '回填档案字段',
  fieldId: '字段',
  numberCopies: '加印份数',
  month: '月份',
  employees: '员工',
  parentCode: '父级分类编码',
  whCode: '仓库档案',
  barCode: '对应条形码编码',
  maxCubage: '最大体积',
  maxWeight: '最大重量',
  remark: '备注',
  grade: '编码级别',
  end: '是否末级',
  hasChild: '是否有子节点',

  processCategroyId: ':流程分类',
  processRoleId: ':流程角色',
  role: '流程角色',
  newrole: '角色管理',
  associatedRole: '关联角色',
  contractNumber: '合同编号',
  categroy: '分类',
  teamName: '团队名称',
  productManager: '产品负责人',
  projectManager: '项目负责人',
  testleader: '测试负责人',
  personChargeRelease: '发布负责人',
  softwareOwner: '软件负责人',
  headOfHardware: '硬件负责人',
  relatedProducts: '关联产品',
  project: '项目',
  addSubmodule: '添加子模块',
  product: '产品',
  appName: 'app名称',
  appVersion: 'app版本',
  resourceFileType: '资源文件类型',
  versionDescription: '版本描述',
  activationTime: '激活时间',
  objectId: '对象',
  nodeEntityId: '工作流节点实体',
  expandType: '拓展类型(create:创建,edit:编辑,delete:删除)'

}
