import Vue from "vue";
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import * as dayjs from "dayjs";
import {numFormat} from 'dbcp-frontends-common/lib/utils'
import {store} from 'dbcp-frontends-common/lib/store'

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(dataStr).format(pattern)
})

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(dataStr).format(pattern)
})

/** 字符串超长截取省略号显示 */
Vue.filter('ellipsis', function (value, vlength = 25) {
  if(!value){
    return "";
  }
  if (value.length > vlength) {
    return value.slice(0, vlength) + '...'
  }
  return value
})

Vue.filter('big', function(value) {
    var fraction = ['角', '分'];
    var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    var unit = [ ['元', '万', '亿'], ['', '拾', '佰', '仟'] ];
    var head = value < 0? '欠': '';
    value = Math.abs(value);

    var s = '';

    for (var i = 0; i < fraction.length; i++)
    {
      s += (digit[Math.floor(value * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
    }
    s = s || '整';
    value = Math.floor(value);

    for (var i = 0; i < unit[0].length && value > 0; i++)
    {
      var p = '';
      for (var j = 0; j < unit[1].length && value > 0; j++)
      {
        p = digit[value % 10] + unit[1][j] + p;
        value = Math.floor(value / 10);
      }
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零')  + unit[0][i] + s;
    }
    return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
})

Vue.filter('NumFormat', function(value) {
  return numFormat(value)
})

Vue.filter('MinuteToHour', function(value) {
  if(value == 0){
    return '0min'
  }
  let hourStr = ''
  let minuteStr = ''
  if(Math.floor(value/60) == 0){
    hourStr = ''
  }else {
    hourStr = Math.floor(value/60)+"h  "
  }

  if(value%60 == 0){
    minuteStr = ''
  }else{
    minuteStr = (value%60)+"min"
  }
  return hourStr+minuteStr;
  return value;
})


Vue.use(vueFilterPrettyBytes)
