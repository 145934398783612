(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("antd"), require("Vuex"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["Vue", "antd", "Vuex", "axios"], factory);
	else if(typeof exports === 'object')
		exports["clmApp"] = factory(require("Vue"), require("antd"), require("Vuex"), require("axios"));
	else
		root["clmApp"] = factory(root["Vue"], root["antd"], root["Vuex"], root["axios"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__131a__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 