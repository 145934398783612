import Vue from 'vue'
import enLocal from '@/locales/lang/en'
import zhLocal from '@/locales/lang/zh'
import VueI18n from 'vue-i18n'
import { getLanguage } from '@/store/mutation-types'

Vue.use(VueI18n)

const messages = {
  'en': {
    ...enLocal
  },
  'zh': {
    ...zhLocal
  }
}

const i18n = new VueI18n({
  locale: 'zh',
  messages
});
console.log(i18n)
export default i18n;
