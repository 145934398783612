import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
// import router from './router'
import { router } from 'dbcp-frontends-common/lib/router'

import { VueAxios } from 'dbcp-frontends-common/lib/utils'
// import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'  // or 'ant-design-vue/dist/antd.less'
// Vue.use(Antd)

import Viser from 'viser-vue'
import '@/permission' // permission control
import '@/utils/filter' // base filter
import Print from 'vue-print-nb-jeecg'
/*import '@babel/polyfill'*/
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

if (!window.__POWERED_BY_QIANKUN__) {
  require('@jeecg/antd-online-beta220')
  require('@jeecg/antd-online-beta220/dist/OnlineForm.css')
}

import 'dbcp-frontends-common/lib/common/index.css'

//引入mavonEditor
import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)


import {
  ACCESS_TOKEN,
  DEFAULT_COLOR,
  DEFAULT_THEME,
  DEFAULT_LAYOUT_MODE,
  DEFAULT_COLOR_WEAK,
  SIDEBAR_TYPE,
  DEFAULT_FIXED_HEADER,
  DEFAULT_FIXED_HEADER_HIDDEN,
  DEFAULT_FIXED_SIDEMENU,
  DEFAULT_CONTENT_WIDTH_TYPE,
  DEFAULT_MULTI_PAGE, USER_NAME, USER_ID
} from '@/store/mutation-types'
import config from '@/defaultSettings'

import JDictSelectTag from './components/dict/index.js'
import { hasPermission } from 'dbcp-frontends-common/lib/utils'
import vueBus from '@/utils/vueBus'
import JeecgComponents from '@/components/jeecg/index'
import '@/assets/less/JAreaLinkage.less'
import VueAreaLinkage from 'vue-area-linkage'
//导入Json编辑器
import JsonEditor from 'vue-json-edit'

Vue.config.productionTip = false

import AuthImage from '@/plugins/auth-image'

// register vue-auth-image directive
Vue.use(AuthImage)

Vue.use(Storage, config.storageOptions)

Vue.use(VueAxios, router)
Vue.use(Viser)
Vue.use(hasPermission)
Vue.use(JDictSelectTag)
Vue.use(Print)
Vue.use(preview)
Vue.use(vueBus)
Vue.use(JeecgComponents)
Vue.use(VueAreaLinkage)
Vue.use(JsonEditor)

// utils
import { global } from 'dbcp-frontends-common/lib/utils'

Vue.prototype.Global = global
Vue.prototype.$g = global.getDictItemPlus
Vue.prototype.$gt = global.getDictItemTreePlus
Vue.prototype.$gl = global.getDictItem

Vue.prototype.lodash = require('lodash')

// i18n
import i18n from '@/locales'

// 隐藏部门分配
Vue.ls.set('hideDept', true)

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
import '@/utils/flexible'
// import sentry log system in production
let instance = null
//会导致菜单没有

import { store } from 'dbcp-frontends-common/lib/store'
import canvas from '@/store/canvas'
import event from '@/store/event'


// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
if (process.env.NODE_ENV !== 'development') {
  console.log('process.env.NODE_ENV',process.env.NODE_ENV)
  // Sentry.init({
  //   Vue,
  //   dsn: 'https://dce08884ab3a41d493ed0e3b832d2431@sentry.service.duubee.com/21',
  //   // debug:true,
  //   // integrations: [
  //   //   new Integrations.BrowserTracing({
  //   //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //   //     logErrors: true,
  //   //   })
  //   // ],
  //   release: process.env.VUE_APP_RELEASE,
  //   environment: process.env.NODE_ENV,
  //   beforeSend(event) {
  //
  //     if (event.user === undefined) event.user = {}
  //
  //     event.user.departId = store.getters.userInfo.departId
  //     event.user.workNo = store.getters.userInfo.workNo
  //     event.user.username = store.getters.userInfo.username
  //     event.user.orgCode = store.getters.userInfo.orgCode
  //     event.user.realname = store.getters.userInfo.realname
  //
  //     return event;
  //   },
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0
  // })
}

store.registerModule('canvas', canvas)
store.registerModule('event', event)

store.state.user.url = '/workflow/pendingTasksWorkflowView/list'
store.state.iconName = process.env.NODE_ENV === 'client' ? 'favicon.ico' : 'favicon2.ico'
store.state.config.loginLogo = process.env.NODE_ENV === 'client' ? require('./assets/images/logo_leiweicity.png') : require('./assets/images/logo.png')
store.state.config.sidebarRetractLogo = process.env.NODE_ENV === 'client' ? require('./assets/images/logo_leiweicity_net.png') : require('./assets/images/logo.svg')
store.state.config.sidebarLogo = process.env.NODE_ENV === 'client' ? require('./assets/images/logo_leiweicity_line.png') : require('./assets/images/logo_rectangle_white.png')
store.state.config.appName = process.env.NODE_ENV === 'client' ? '城更管控宝' : '度比城市更新管理系统'
store.state.config.logoCopyright = process.env.NODE_ENV === 'client' ? `<div><a href='https://beian.miit.gov.cn/' target='_blank'>粤ICP备2021156599号</a></div> `
  : `<a href='https://www.duubee.com' target='_blank'>${process.env.VUE_APP_COMPANY}</a> `,
  console.log('环境', process.env.NODE_ENV)
store.state.config.logoStyle = process.env.NODE_ENV === 'client' ? {
  'height': '177px',
  'width': '177px',
  'marginTop': '0px',
  'marginRight': '0',
  'borderStyle': 'none',
  'marginBottom': '30px'
} : {
  'height': '30px',
  'marginTop': '120px',
  'verticalAlign': 'top',
  'borderStyle': 'none'
}
store.state.config.permissionModal.hideModule = true
store.state.config.permissionModal.hideMiniProgramsId = true
store.state.config.userModal = {
  hideWorkNo: true,
  hidePost: true,
  hideType: true,
  hideTelephone: true,
  hideDingtalkUserId: true,
  hideDept: true
}
store.state.config.announcementPermission = false
store.state.config.noticePermission = false
store.state.config.userListColumns = [
  /*{
      title: '#',
      dataIndex: '',
      key:'rowIndex',
      width:60,
      align: 'center',
      customRender:function (t,r,index) {
        return parseInt(index)+1;
      }
    },*/
  {
    title: i18n.tc('username'),
    align: 'center',
    dataIndex: 'username',
    width: 120,
    sorter: true
  },
  {
    title: i18n.tc('actualName'),
    align: 'center',
    width: 100,
    dataIndex: 'realname'
  },
  {
    title: i18n.tc('avatar'),
    align: 'center',
    width: 120,
    dataIndex: 'avatar',
    scopedSlots: { customRender: 'avatarslot' }
  },

  {
    title: i18n.tc('gender'),
    align: 'center',
    width: 80,
    dataIndex: 'sex_dictText',
    sorter: true
  },
  {
    title: i18n.tc('birthday'),
    align: 'center',
    width: 100,
    dataIndex: 'birthday'
  },
  {
    title: i18n.tc('mobilePhoneNumber'),
    align: 'center',
    width: 100,
    dataIndex: 'phone'
  },
  {
    title: i18n.tc('status'),
    align: 'center',
    width: 80,
    dataIndex: 'status_dictText'
  },
  {
    title: i18n.tc('action'),
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 170
  }
]

function render(props = {}) {

  let BaseStore = window.__POWERED_BY_QIANKUN__ ? props.store : store
  const { container } = props
  Vue.observable(store)
  instance = new Vue({
    i18n,
    router,
    store: BaseStore,
    mounted() {
      Vue.ls.set('specialLogin', false)
      Vue.ls.set('APP_NAME', process.env.VUE_APP_NAME)
      Vue.ls.set('APP_NAME_EN', 'cum')
      Vue.ls.remove('DEFAULT_THEME')
      Vue.ls.set('DEFAULT_THEME', 'dark')
      Vue.ls.remove('Language')
      Vue.ls.set('Language', 'zh')
      store.commit('SET_SIDEBAR_TYPE', Vue.ls.get(SIDEBAR_TYPE, true))
      store.commit('TOGGLE_THEME', Vue.ls.get(DEFAULT_THEME, config.navTheme))
      store.commit('TOGGLE_LAYOUT_MODE', Vue.ls.get(DEFAULT_LAYOUT_MODE, config.layout))
      store.commit('TOGGLE_FIXED_HEADER', Vue.ls.get(DEFAULT_FIXED_HEADER, config.fixedHeader))
      store.commit('TOGGLE_FIXED_SIDERBAR', Vue.ls.get(DEFAULT_FIXED_SIDEMENU, config.fixSiderbar))
      store.commit('TOGGLE_CONTENT_WIDTH', Vue.ls.get(DEFAULT_CONTENT_WIDTH_TYPE, config.contentWidth))
      store.commit('TOGGLE_FIXED_HEADER_HIDDEN', Vue.ls.get(DEFAULT_FIXED_HEADER_HIDDEN, config.autoHideHeader))
      store.commit('TOGGLE_WEAK', Vue.ls.get(DEFAULT_COLOR_WEAK, config.colorWeak))
      store.commit('TOGGLE_COLOR', Vue.ls.get(DEFAULT_COLOR, config.primaryColor))
      store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
      store.commit('SET_MULTI_PAGE', Vue.ls.get(DEFAULT_MULTI_PAGE, config.multipage))
      store.commit('SET_USERNAME', Vue.ls.get(USER_NAME))
      store.commit('SET_USERID', Vue.ls.get(USER_ID))
    },
    render: h => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app') //这里是挂载到自己的html中
}

if (window.__POWERED_BY_QIANKUN__) {
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}


//判断qiankun是否是支持
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

//子组件的协议
export async function bootstrap(props) {
}

export async function mount(props) {
  console.log('props', props)
  Vue.prototype.$onGlobalStateChange = props.onGlobalStateChange


  render(props)
}





//卸载
export async function unmount(props) {
  console.log('$destroy')
  instance.$destroy()
}
