import { isURL } from 'dbcp-frontends-common/lib/utils'

import * as allViews from 'dbcp-frontends-common/lib/views'

import axios from 'axios'
let onlineCommons


// 生成首页路由
export async function generateIndexRouter(data) {
  return new Promise((async (resolve, reject) => {
    let url = ''
    if(process.env.NODE_ENV === 'production'){
      url = 'https://file.duubee.com/webResource/OnlineForm.umd.min.js'
    }else{
      url = 'https://file.duubee.com/webResource/OnlineForm.umd.min.js'
    }
    axios.get(url).then(async (res)=>{
      eval(res.data)
      onlineCommons =window.OnlineForm.default
      //console.log(window.OnlineForm.default)
      let indexRouter = [{
        path: '/',
        name: 'dashboard',
        //component: () => import('@/components/layouts/BasicLayout'),
        component: resolve => require(['@/components/layouts/TabLayout'], resolve),
        meta: { title: 'dashboard' },
        redirect: '/dashboard/analysis',
        children: [
          ...(await generateChildRouters(data))
        ]
      }, {
        'path': '*', 'redirect': '/404', 'hidden': true
      }]
      resolve(indexRouter)
    })

  }))
}


// 生成嵌套路由（子路由）

async function generateChildRouters(data) {
  return new Promise((async (resolve, reject) => {
    const routers = []
    const systemArr = ['system']
    for (let item of data) {
      let component = ''
      if (item.component.indexOf('layouts') >= 0) {
        component = 'components/' + item.component
      } else {
        component = 'views/' + item.component
      }

      // eslint-disable-next-line
      let URL = (item.meta.url || '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
      if (isURL(URL)) {
        item.meta.url = URL
      }
      //online菜单路由加载逻辑
      let componentPath

      if (item.component == 'modules/online/cgform/OnlCgformHeadList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformHeadList
      } else if (item.component == 'modules/online/cgform/OnlCgformCopyList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformCopyList
      } else if (item.component == 'modules/online/cgform/auto/OnlCgformAutoList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformAutoList
      } else if (item.component == 'modules/online/cgform/auto/OnlCgformTreeList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformTreeList
      } else if (item.component == 'modules/online/cgform/auto/erp/OnlCgformErpList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformErpList
      } else if (item.component == 'modules/online/cgform/auto/innerTable/OnlCgformInnerTableList') {
        componentPath = onlineCommons && onlineCommons.OnlCgformInnerTableList
      } else if (item.component == 'modules/online/cgreport/OnlCgreportHeadList') {
        componentPath = onlineCommons && onlineCommons.OnlCgreportHeadList
      } else if (item.component == 'modules/online/cgreport/auto/OnlCgreportAutoList') {
        componentPath = onlineCommons && onlineCommons.OnlCgreportAutoList
      } else {
        let tempStr = item.component
        let routerStr = tempStr.substring(0,tempStr.lastIndexOf('/'))
        let moduleStr = tempStr.substring(tempStr.lastIndexOf('/') + 1,tempStr.length)
        if(systemArr.includes(routerStr)){
          componentPath = allViews[moduleStr]
        }else{
          componentPath = resolve => require(['@/' + component + '.vue'], resolve)
        }

      }
      let menu = {
        path: item.path,
        name: item.name,
        redirect: item.redirect,
        component: componentPath,
        hidden: item.hidden,
        //component:()=> import(`@/views/${item.component}.vue`),
        meta: {
          title: item.meta.title,
          icon: item.meta.icon,
          url: item.meta.url,
          permissionList: item.meta.permissionList,
          keepAlive: item.meta.keepAlive,
          /*update_begin author:wuxianquan date:20190908 for:赋值 */
          internalOrExternal: item.meta.internalOrExternal
          /*update_end author:wuxianquan date:20190908 for:赋值 */
        }
      }
      if (item.alwaysShow) {
        menu.alwaysShow = true
        menu.redirect = menu.path
      }
      if (item.children && item.children.length > 0) {
        menu.children = [...await generateChildRouters(item.children)]
      }
      //--update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
      //判断是否生成路由
      if (item.route && item.route === '0') {
        //console.log(' 不生成路由 item.route：  '+item.route);
        //console.log(' 不生成路由 item.path：  '+item.path);
      } else {
        routers.push(menu)
      }
      //--update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    }

    resolve(routers)
  }))
}

export function getProjectStatusColor(value) {
  let color
  switch (value) {
    //进行中
    case '0':
      color = 'blue'
      break
    //已完成
    case '1':
      color = 'green'
      break
  }
  return color
}

export function getProjectItemStatusColor(value) {
  let color
  switch (value) {
    //已签约
    case '3':
      color = 'green'
      break
    //未审核
    case '1':
      color = 'blue'
      break
    case '0':
      color = 'blue'
      break
  }
  return color
}

export function getRightHolderCheckedStatusColor(value) {
  let color
  switch (value) {
    //已核实
    case '1':
      color = 'green'
      break
    //未核实
    case '0':
      color = 'red'
      break
  }
  return color
}

export function getIsEntrustedNotarizationColor(value) {
  let color
  switch (value) {
    //是
    case 1:
      color = 'green'
      break
    //否
    case 0:
      color = 'red'
      break
  }
  return color
}

export function getIsCancellationRealEstateLicenseColor(value) {
  let color
  switch (value) {
    //是
    case 1:
      color = 'green'
      break
    //否
    case 0:
      color = 'red'
      break
  }
  return color
}

export function getSurveyingStatusColor(value) {
  let color
  switch (value) {
    //是
    case '1':
      color = 'green'
      break
    //否
    case '0':
      color = 'red'
      break
  }
  return color
}

export function getContractStatusStatusColor(value) {
  let color
  switch (value) {
    //是
    case 5:
      color = 'green'
      break
    //否
    case -1:
      color = 'red'
      break
    case 0:
      color = 'red'
      break
    case 4:
      color = 'red'
      break
    case 6:
      color = 'red'
      break
  }
  return color
}

export function getFirstPayStatusColor(value) {
  let color
  switch (value) {
    //是
    case 5:
      color = 'green'
      break
    //否
    case -1:
      color = 'red'
      break
    case 1:
      color = 'yellow'
      break
    case 2:
      color = 'green'
      break
    case 0:
      color = 'red'
      break
    case 4:
      color = 'red'
      break
    case 6:
      color = 'red'
      break
    case 35:
      color = 'red'
      break
  }
  return color
}


/**
 * 防抖函数
 * @param fn 要防抖的函数
 * @param wait 防抖的毫秒数
 **/
export function debounce(fn, wait){
  let timer, startTimeStamp=0;
  let context, args;

  let run = (timerInterval)=>{
    timer= setTimeout(()=>{
      let now = (new Date()).getTime();
      let interval=now-startTimeStamp
      if(interval<timerInterval){ // the timer start time has been reset, so the interval is less than timerInterval
        console.log('debounce reset',timerInterval-interval);
        startTimeStamp=now;
        run(wait-interval);  // reset timer for left time
      }else{
        fn.apply(context,args);
        clearTimeout(timer);
        timer=null;
      }

    },timerInterval);
  }

  return function(){
    context=this;
    args=arguments;
    let now = (new Date()).getTime();
    startTimeStamp=now;

    if(!timer){
      console.log('debounce set',wait);
      run(wait);    // last timer alreay executed, set a new timer
    }
  }
}


