// import { axios } from 'dbcp-frontends-common/lib/utils'
import axios from 'axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'


let vueAuthImage = {}

function setImgSrc(el, binding, vnode, oldVnode) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value
    axios({
      method: 'get',
      url: imageUrl,
      responseType: 'arraybuffer',
      headers: {
        'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)
      }
    })
      .then(function(resp) {
        if (resp.headers['content-type'] === "application/json") {
          let tempEl = document.createElement('div')
          tempEl.id = 'tempEl'
          tempEl.innerHTML = `<div class="img" style="display: inline-block;zoom: 1;padding-right: 52px;">
                <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" style="height: 360px;max-width: 430px;"/>
            </div>
             <div style="display: inline-block;flex: auto;">
                <h1 style="color: #434e59;font-size: 72px;font-weight: 600;line-height: 72px;margin-bottom: 24px;">403</h1>
             <div style="color: rgba(0, 0, 0, .45);font-size: 20px;line-height: 28px;margin-bottom: 16px;">抱歉，你无权访问该页面</div>
    </div>`
          tempEl.style.minHeight = '400px'
          tempEl.style.height = '80%'
          tempEl.style.alignItems = 'center'
          tempEl.style.textAlign = 'center'
          // tempEl.style.marginTop = '150px'

          let oldTempEl = document.getElementById('tempEl')
          if(oldTempEl){
            el.parentNode.removeChild(oldTempEl)
          }
          el.parentNode.appendChild(tempEl)
          el.style.display = 'none'
        } else {
          const mimeType = resp.headers['content-type'].toLowerCase()
          const imgBase64 = new Buffer(resp.data, 'binary').toString('base64')
          el.src = `data:${mimeType};base64,${imgBase64}`
          Vue.set(vnode.componentInstance, 'src', `data:${mimeType};base64,${imgBase64}`)
          let tempEl = document.getElementById('tempEl')
          el.parentNode.removeChild(tempEl)
          el.style.display = 'block'
        }
      }).catch((function(err) {
      console.error(err)
      // el.src = imageUrl;
    }))
  }
}

vueAuthImage.install = function(Vue) {
  Vue.directive('auth-image', {
    bind: function(el, binding, vnode, oldVnode) {
      setImgSrc(el, binding, vnode, oldVnode)
    },
    componentUpdated: function(el, binding, vnode) {
      setImgSrc(el, binding, vnode)
    },
  })
}

export default vueAuthImage
