import Vue from 'vue'
import defaultSettings from '../defaultSettings'

export const ACCESS_TOKEN = 'Access-Token'
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const DEFAULT_THEME = 'DEFAULT_THEME'
export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE'
export const DEFAULT_COLOR = 'DEFAULT_COLOR'
export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK'
export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER'
export const DEFAULT_FIXED_SIDEMENU= 'DEFAULT_FIXED_SIDEMENU'
export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN'
export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE'
export const DEFAULT_MULTI_PAGE = 'DEFAULT_MULTI_PAGE'
export const USER_NAME = 'Login_Username'
export const USER_ID = 'Login_UserId'
export const USER_INFO = 'Login_Userinfo'
export const USER_AUTH = 'LOGIN_USER_BUTTON_AUTH'
export const SYS_BUTTON_AUTH = 'SYS_BUTTON_AUTH'
export const ENCRYPTED_STRING = 'ENCRYPTED_STRING'
export const ENHANCE_PRE = 'enhance_'
// export const UI_CACHE_DB_DICT_DATA = 'UI_CACHE_DB_DICT_DATA'
export const UI_CACHE_DB_DICT_DATA_EN = 'UI_CACHE_DB_DICT_DATA_EN'
export const UI_CACHE_DB_DICT_DATA_ZH = 'UI_CACHE_DB_DICT_DATA_ZH'
export const INDEX_MAIN_PAGE_PATH =  "/dashboard/analysis"

//请求头和缓存的语言key
export const LANGUAGE = "Language"

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

/**
 * 通过语言获取语言key
 * @returns {string}
 */
export function getDictDataKeyByLanguage(){

  var language = getLanguage();

  if(!language){
    language = (navigator.language || navigator.browserLanguage).toLowerCase().substr(0, 2);
  }

  switch (language) {
  case "zh":
    return UI_CACHE_DB_DICT_DATA_ZH
  case "en":
    return UI_CACHE_DB_DICT_DATA_EN
  }
  return UI_CACHE_DB_DICT_DATA_EN
}

/**
 * 获取语言
 * @returns {string}
 */
export function getLanguage(){
  var language = (Vue && Vue.ls && Vue.ls.get && Vue.ls.get(LANGUAGE)) || null;
  if(!language){
    var result = window.localStorage.getItem(defaultSettings.storageOptions.namespace + LANGUAGE);
    if(result){
      result = JSON.parse(result)
      if(result && result.hasOwnProperty("value")){
        language = result.value;
      }
    }
  }
  return language || process.env.VUE_APP_DEFAULT_LANGUAGE_CODE;
}
