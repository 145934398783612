const canvas = {
    state:{
        data: {
            scale: 1,
            lineName: 'polyline',
            fromArrowType: '',
            toArrowType: 'triangleSolid',
            locked: 0
        }
    },
    mutations:{
        data(state, data) {
            state.data = data
        }
    }
}

export default canvas
