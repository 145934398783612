export default {
  logout: 'Logout',
  language: 'Language',
  error: 'Error',
  alert: 'Alert',
  logoutAlert: 'Do you want to log out?',
  loadingHint: 'Loading...',
  searchMenu: 'Search menu',
  welcome: 'Welcome',
  seeMore: 'More',
  publish: 'Publish',
  notification: 'Notification',
  announcement: 'Announcement',
  viewDetail: 'View detail',
  notifications: 'Notifications',
  title: 'Title',
  fieldEmptyHint: 'Field can\' not be empty',
  incorrectValue: 'Incorrect value',
  goodMorning: 'Good morning',
  goodNight: 'Good night',
  goodAfternoon: 'Good afternoon',
  messageType: 'Message type',
  publisher: 'Publisher',
  publishDate: 'Publish date',
  priority: 'Priority',
  search: 'Search',
  reset: 'Reset',
  readAll: 'Read all',
  readStatus: 'Read status',
  view: 'View',
  notificationManagementPage: 'Notification management page',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  haveRead: 'Have read',
  unread: 'Unread',
  action: 'Action',
  actions: 'Actions',
  operationConfirm: 'Operation confirm',
  allMarkedRead: 'All marked read',
  login: 'Login',
  loginFailed: 'Login failed',
  delete: 'Delete',
  lock: 'Lock',
  create: 'Create',
  username: 'Username',
  password: 'Password',
  youHaveNotBelongedToTheDepartmentHint: 'You have not belonged to the department, please confirm the account information',
  incorrectVerificationCode: 'Incorrect verification code',
  verificationCodeIsBeingSent: 'Verification code is being sent',
  age: 'Age',
  dingtalkUserIdAlreadyExists: 'Dingtalk id is exit!',
  pleaseInputPhoneNumber: 'Please input phone number',
  changeAvatar: 'Change avatar',
  cancel: 'Cancel',
  sureToCancel: 'Sure to cancel？',
  sureToDelete: 'Sure to delete？',
  save: 'Save',
  name: 'Name',
  loginPassword: 'Login password',
  email: 'Email',
  baseSettings: 'Base settings',
  newMessages: 'New messages',
  batchDelete: 'Batch delete',
  selected: 'Selected',
  clean: 'Clean',
  edit: 'Edit',
  more: 'More',
  detail: 'Detail',
  addSubordinate: 'Add subordinate',
  dataRule: 'Data rule',
  confirmDeleteAlert: 'Confirm delete?',
  menuName: 'Menu name',
  menuType: 'Menu type',
  component: 'Component',
  path: 'Path',
  sort: 'Sort',
  close: 'Close',
  confirmPassword: 'Confirm password',
  userInfo: 'User info',
  department: 'Department',
  gender: 'Gender',
  mobilePhoneNumber: 'Phone number',
  confirmUnlinkHint: 'Confirm unlink？',
  selectDepartmentAlert: 'Please select department',
  addExistedUser: 'Add existed user',
  userList: 'User list',
  male: 'Male',
  female: 'Female',
  total: 'Total',
  selectUserByDepartment: 'Select user by dept.',
  workNo: 'Work No.',
  saveAndClose: 'Save and close',
  fileUpload: 'File upload',
  warning: 'Warning',
  dataLogWindow: 'Data log window',
  unlink: 'Unlink',
  unlinkUserWithDepartmentAlert: 'Unlink the selected department?',
  list: 'List',
  modify: 'Modify',
  import: 'Import',
  export: 'Export',
  enable: 'Enable',
  disable: 'Disable',
  advanceSearch: 'Advance search',
  rememberMe: 'Remember me',
  item: 'Item',
  collapse: 'Collapse',
  expand: 'Expand',
  batchOperation: 'Batch operation',
  relatedPerson: 'Related person',
  deadline: 'Deadline',
  startTime: 'Start time',
  startDate: 'Start date',
  endTime: 'End time',
  endDate: 'End date',
  noPicture: 'No picture',
  noFile: 'No file',
  treeOperation: 'Tree operation',
  groupId: 'Group id',
  status: 'Status',
  owner: 'Owner',
  position: 'Position',
  teamworkTask: 'Teamwork task',
  comment: 'Comment',
  datetime: 'Datetime',
  node: 'Node id',
  task: 'Task',
  panelName: 'Panel name',
  teamworkPanel: 'Panel',
  teamworkGroup: 'Group',
  accept: 'Accept',
  reject: ' Reject',
  refuse: 'Refuse',
  confirm: 'Confirm',
  notes: 'Notes',
  comments: 'Comments',
  attachments: 'Attachments',
  pleaseSelectHint: 'Please select',
  selectUser: 'Select user',
  add: 'Add',
  block: 'Block',
  assign: 'Assign',
  inputContentHint: 'Please input content',
  selectFileHint: 'Please select file',
  submit: 'Submit',
  complete: 'Complete',
  operationHistory: 'Operation history',
  createTask: 'Create task',
  editTask: 'Edit task',
  createGroup: 'Create group',
  editGroup: 'Edit group',
  mobileNumberAlreadyExists: 'Mobile phone already exists',
  emailAlreadyExists: 'Email already exists',
  usernameAlreadyExists: 'username already exists',
  workNoAlreadyExists: 'Work No. already exists',
  pleaseUploadImage: 'Please upload image',
  changeView: 'Change view',
  viewAll: 'View all',
  groupView: 'Group view',
  panelView: 'Personal view',
  taskView: 'Category view',
  demand: 'Demand',
  description: 'Description',
  positionName: 'Position name',
  positionQuota: 'Establishment',
  help: 'Help',
  positionAdvertise: 'Position advertise',
  minSalary: 'Min salary',
  maxSalary: 'Max salary',
  serviceEmployee: 'Employee',
  order: 'Order',
  companyName: 'Company name',
  bu: 'Bu',
  jobResponsibilities: 'Job responsibility',
  jobRequirements: 'Job requirements',
  annualSalaryRange: 'Annual salary range',
  saveDraft: 'Save as draft',
  createTime: 'Create time',
  createBy: 'Create by',
  administrativeSuperior: 'Administrative superior',
  administration: 'Administration',
  contractPeriod: 'Contract period (months)',
  company: 'Company',
  mentor: 'Mentor',
  site: 'Site',
  functionalSuperior: 'Functional superior',
  related: 'Relevant Person',
  reportDate: 'Report date',
  salaryProbation: 'salaryProbation',
  probationarySalary: 'Probationary salary',
  probationaryTime: 'Probationary time(month)',
  regularSalary: 'Regular salary',
  taskSubdivide: 'Subdivide',
  currentProcessor: 'Current processor',
  queryByStatus: 'Query by status',
  businessUnit: 'Business unit',
  graduationDate: 'Graduation date',
  idType: 'ID type',
  idCard: 'Id card',
  offer: 'Offer',
  currentProcessIssues: 'Current issues',
  graduatedSchool: 'Graduated school',
  politicalStatus: 'Political status',
  maritalStatus: 'Marital status',
  discipline: 'Discipline',
  birthday: 'Birthday',
  birthplace: 'Birthplace',
  accountBank: 'Account bank',
  bankAccountNumber: 'Account number',
  applicant: 'Applicant',
  currency: 'Currency',
  amount: 'Amount',
  amountBig: 'Amount Big',
  itemDetail: 'Detail',
  itemCategory: 'Category',
  reimbursementDetail: 'Reimbursement detail',
  print: 'Print',
  amountTotal: 'Total',
  amountTotalBig: 'Total Big',
  applicationDate: 'Application date',
  serialNumber: 'Serial number',
  signatory: 'Signatory',
  Cashier: 'Cashier',
  financialOfficer: 'Financial officer',
  personnel: 'Personnel',
  location: 'Location',
  cause: 'Cause',
  trafficType: 'Traffic type',
  trafficRemark: 'Traffic remark',
  accommodationType: 'Accommodation type',
  accommodationRemark: 'Accommodation remark',
  schedule: 'Schedule',
  loanDemand: 'Loan demand',
  useing: 'Useing',
  receivingUnit: 'Receiving unit',
  paymentUnit: 'Payment unit',
  procurementUnit: 'Procurement unit',
  receive: 'Receive',
  reason: 'Reason',
  beginTime: 'Begin time',
  hospitalityUnit: 'Hospitality unit',
  attendees: 'Attendees',
  emergencyContactName: 'Emergency contact name',
  emergencyContactNumber: 'Emergency contact number',
  education: 'Education',
  teamworkCategory: 'Category',
  selectStartDateHint: 'Please select start date',
  selectEndDateHint: 'Please select end date',
  attendanceHolidayConfig: 'Attendance holiday configuration',
  attendanceTimeConfig: 'Attendance time configuration',
  attendanceType: 'Attendance type',
  historyRecords: 'History records',
  attendanceAbnormal: 'Attendance abnormal',
  attendanceDate: 'Attendance date',
  applicationTimesOfMonth: 'Application times of month',
  applicationUnit: 'Application unit',
  encrypt: 'Encrypt',
  applicationHistory: 'Application history',
  approvalHistory: 'Approval history',
  allRecords: 'All records',
  dept: 'Dept.',
  yes: 'Yes',
  no: 'No',
  hr: 'HR',
  employingDepartment: 'Employing department',
  category: 'Classification',
  borrowReason: 'Reason for borrowing',
  procurementReason: 'Purchasing reasons',
  ccToMe: 'CC to me',
  filter: 'Filter',
  time: 'Time',
  user: 'User',
  teamworkActivity: 'Teamwork Activity Statistics',
  dataSheet: 'dataSheet',
  dataLength: 'Data Length',
  departmentSelect: 'Department Selection',
  applicationReason: 'Reason for application',
  applicationType: 'Application type',
  sealType: 'Seal type',
  leaveType: 'Type of leave',
  date: 'Date',
  noonAttendance: 'Attendance at noon',
  morningWorkTime: 'Morning work time',
  MorningOffTime: 'Morning off time',
  AfternoonWorkTime: 'Afternoon work time',
  AfternoonOffTime: 'Afternoon off time',
  flexibleTime: 'Flexible time',
  workTime: 'Work time',
  profile: 'Profile',
  earliestRecord: 'Earliest record',
  latestRecord: 'Latest record',
  attendanceId: 'Attendance id',
  attendanceTime: 'Attendance time',
  processed: 'Processed',
  synchronisedTime: 'Synchronised time',
  basicInfo: 'Basic Information',
  departmentRole: 'Department role',
  invalidDepartInfo: 'There is no valid department information under your department!',
  noPermissionEmployeeInfo: 'Temporary permission for ordinary employees!',
  institutionName: 'Institution name',
  higherOffice: 'Higher office',
  institutionCode: 'Institution code',
  institutionType: 'Institution type',
  fax: 'Fax',
  mobile: 'Mobile',
  departmentRoleName: 'Department role name',
  departmentRoleCode: 'Department role code',
  addDepRole: 'Add department role',
  authorization: 'Authorization',
  addDept: 'Add department',
  addAnother: 'Add',
  selectSubDeptWarn: 'Please click to select the superior department!',
  successDelete: 'Successfully deleted',
  failDelete: 'Failed to delete',
  selectRecordWarn: 'Please select a record!',
  selectDeptWarn: 'Please select a department first!',
  currentSelection: 'Current selection',
  cancelSelection: 'Cancel selection',
  fatherSonConnection: 'Father-son connection',
  checkAll: 'Check all',
  unselectAll: 'Unselect all',
  expandAll: 'Expand All',
  mergeAll: 'Merge All',
  deptAuthority: 'Department authority',
  actualName: 'Actual name',
  userStatus: 'User status',
  ordinary: 'Normal',
  freeze: 'Freeze',
  isFreeze: 'Are you sure to freeze?',
  addUser: 'Add users',
  recycleBin: 'Recycle Bin',
  roleAssignments: 'Role Assignments',
  roleAssignmentsDept: 'Department role assignment',
  deptAllocation: 'Department allocation',
  avatar: 'Avatar',
  upload: 'Upload',
  select: 'Select',
  jobTitle: 'Job title',
  job: 'Job',
  landline: 'Landline',
  dingtalkUserId: 'Dingding user ID',
  obtain: 'Obtain',
  unFindDingTalkIdInfo: 'The DingTalk user ID of the mobile number cannot be found',
  giveUpEditInfo: 'Are you sure to give up editing?',
  agent: 'Agent',
  unfreeze: 'Unfreeze',
  resetPwd: 'Reset password',
  inputPwdInfo: 'The password consists of 8 digits, uppercase and lowercase letters and special symbols!',
  userAgentSetting: 'User agent settings',
  agentUserName: 'Agent username',
  agentStartTime: 'Agent start time',
  agentEndTime: 'Agent end time',
  valid: 'Valid',
  invalid: 'Invalid',
  responsibleDept: 'Responsible department',
  adminNotAllow: 'Administrator account does not allow this operation',
  recycleBinUser: 'User recycle bin',
  batchRestore: 'Batch Restore',
  restoreUser: 'Restore User',
  removeCompletely: 'Remove completely',
  deleteWarning: 'Note: After completely deleted, it cannot be restored, please operate with caution!',
  entryDept: 'Entry department',
  resume: 'Resume',
  associateOffer: 'Offer',
  certificateType: 'Certificate type',
  licenseNumber: 'License number',
  postalCode: 'Postal code',
  entryTime: 'Entry Time',
  nation: 'Nation',
  use: 'Use',
  photo: 'Photo',
  currentType: 'Current type',
  reasonPayment: 'Reason for payment',
  borrower: 'Borrower',
  bankAccount: 'Bank account',
  repaymentDate: 'Estimated repayment date',
  reimbursementUnit: 'Reimbursement unit',
  deliveryDate: 'Expected delivery date',
  purchaseType: 'Purchase type',
  paymentMethod: 'Payment method',
  businessReasons: 'Business reasons',
  moduleName: 'Module name (function name)',
  moduleKey: 'Module Key (Unique)',
  functionId: 'Function id',
  stateValue: 'State value',
  upperStateValue: 'Upper-level process status value',
  subStateJson: 'Subordinate actions and corresponding status JSON',
  approverUsername: 'Approver username',
  alternateApprover: 'Alternate approver',
  nodeType: 'Node type',
  approverRules: 'Approver rules',
  alternateApproverRules: 'Alternate approver rules',
  roleName: 'Role name',
  newRole: 'New role',
  roleCode: 'Role coding',
  existingUsers: 'Existing users',
  addExistingUsers: 'Add existing users',
  selectRoleWarning: 'Please choose a role!',
  rolePermissionConfig: 'Role permission configuration',
  permissions: 'Permissions',
  primaryMenu: 'Primary menu',
  submenu: 'Submenu',
  btnOrPermission: 'Button/Permission',
  module: 'Module',
  menuPath: 'Menu path',
  menuComponent: 'Menu component',
  defaultJumpAddress: 'Default jump address',
  authorizationId: 'Authorization ID',
  authorizationStrategy: 'Authorization strategy',
  menuIcon: 'Menu icon',
  whetherRouteMenu: 'Whether to route the menu',
  directionalIcon: 'Directional icons',
  indicativeIcon: 'Indicative icons',
  editIcon: 'Edit icons',
  dataIcon: 'Data icons',
  websiteUniversalIcon: 'Website universal icons',
  brandAndLogo: 'Brand and logo',
  noIconSelected: 'No icon selected',
  hiddenRoute: 'Hidden route',
  whetherCacheRoute: 'Whether to cache routing',
  aggregateRoute: 'Aggregate routing',
  openMethod: 'Open method',
  external: 'External',
  internal: 'Internal',
  checkWarning: 'Please check the type and information you fill in is correct',
  enterWarning: 'Please enter a positive integer!',
  existAuthoIdWarning: 'Authorization ID already exists!',
  upperMenu: 'Upper menu',
  seleceUpperMenuWarning: 'Please select the upper menu',
  dataPermissionRule: 'Data permission rules',
  ruleName: 'Rule name',
  ruleValue: 'Rule value',
  ruleField: 'Rule field',
  addSubmenu: 'Add submenu',
  conditionalRule: 'Conditional rules',
  dictionaryName: 'Dictionary name',
  dictionaryNumber: 'Dictionary code',
  refreshCache: 'Refresh cache',
  dictionaryConfig: 'Dictionary configuration',
  dictionaryList: 'Dictionary list',
  dictionaryRetrieval: 'Dictionary retrieval',
  dataValue: 'Data value',
  sortValue: 'Sort value',
  sortValueInfo: 'The smaller the value, the higher the front, and decimals are supported',
  whetherEnable: 'Whether to enable',
  parentNode: 'Parent node',
  sortCode: 'Classification code',
  addSubcategory: 'Add subcategory',
  notificationType: 'Notification type',
  designatedUser: 'Designated user',
  allUsers: 'All users',
  Summary: 'Summary',
  content: 'Content',
  calendar: 'Calendar',
  announceNotify: 'Notification / Announcement',
  upcoming: 'Todo',
  accountSubjects: 'Accounting subjects',
  licenseCategory: 'License Category',
  useCategory: 'Use Category',
  workCategory: 'Work category',
  accountCategory: 'Account category',
  countersigner: 'Countersigner',
  suggest: 'Suggest',
  dateOfResignation: 'Date Of Resignation',
  ethnic:'Ethnic',
  contact:'Contact',
  judges: 'Judges',
  result:'Result',
  averageScore:'AverageScore',
  introducer:'Introducer',
  qualified: 'Qualified',
  defenseTime:'Defense Time',
  ictureGuid:'Icture Guid',
  leadSource:'Lead Source',
  rcId:'Rc Id',
  assistantName:'Assistant Name',
  conEDate:'Con EDate',
  rcType:'Rc Type',
  approvedExpense:'Approved Expense',
  doNotCall:'Do Not Call',
  expense:'Expense',
  faxOptOut:'Fax Opt Out',
  conDepart:'Con Depart',
  wechat:'Wechat',
  assistantPhone:'Assistant Phone',
  emailOptOut:'Email Opt Out',
  code:'Code',
  contactName:'Contact Name',
  cusCode:'Cus Code',
  appellation:'Appellation',
  sex:'Sex',
  hometown:'Hometown',
  dutyId:'Duty Id',
  superiorId:'Superior Id',
  mobilePhone:'Mobile Phone',
  officePhone:'Office Phone',
  homePhone:'Home Phone',
  call:'Call',
  homePage:'Home Page',
  workAddress:'Work Address',
  zipcode:'Zip code',
  marriageId:'Marriage Id',
  homeMember:'Home Member',
  homeAddress:'Home Address',
  tasteId:'Taste Id',
  major:'Major',
  memo:'Memo',
  principal:'Principal',
  addBy:'Add By',
  addTime:'Add Time',
  updateBy:'Update By',
  updateTime:'Update Time',
  unit:'Unit',
  linkPerson:'LinkPerson',
  isDefault:'IsDefault',
  englishAddress:'EnglishAddress',
  englishAddress2:'EnglishAddress2',
  englishAddress3:'EnglishAddress3',
  englishAddress4:'EnglishAddress4',
  invoicecompany:'Invoice company',
  appraisalMonth:'Appraisal Month',
  typeName:'TypeName',
  cardNum:'CardNum',
  phone:'Phone',
  character:'Character',
  marriage:'Marriage',
  village:'Village',
  propertyType:'PropertyType',
  surveyedArea:'SurveyedArea',
  surveyNumber:'SurveyNumber',
  surveyingStatus:'SurveyingStatus',
  landNumber:'LandNumber',
  rightHolder:'RightHolder',
  buildingNumber:'BuildingNumber',
  totalFloors:'TotalFloors',
  structureType:'StructureType',
  ancestralHouse:'AncestralHouse',
  realEstateLicenseType:'RealEstateLicenseType',
  buildingId:'BuildingId',
  floor:'Floor',
  roomNo:'RoomNo',
  certificateConstructionArea:'CertificateConstructionArea',
  increasedConstructionArea:'IncreasedConstructionArea',
  deptId:'DeptId',
  projectId:'ProjectId',
  unitId:'UnitId',
  follower:'Follower',
  negotiationDifficultyLevel:'NegotiationDifficultyLevel',
  isEntrustedNotarization:'IsEntrustedNotarization',
  isCancellationRealEstateLicense:'IsCancellationRealEstateLicense',
  projectItemId:'ProjectItemId',
  ownerId:'OwnerId',
  visitTime:'VisitTime',
  visitStaff:'VisitStaff',
  visitTarget:'VisitTarget',
  carryGifts:'CarryGifts',
  problem:'Problem',
  reply:'Reply',
  instructions:'Instructions',
  nextVisitTime:'NextVisitTime',
  area:'Area',
  demolitionArea:'DemolitionArea',
  fetchableArea:'FetchableArea',
  selectedRoomNo:'SelectedRoomNo',
  actualFetchArea:'ActualFetchArea',
  areaCompensationAmount:'AreaCompensationAmount',
  areaCompensationPaymentStatus:'AreaCompensationPaymentStatus',
  relocationConfirmed:'RelocationConfirmed',
  deleted:'Deleted',
  handoverTime:'HandoverTime',
  contractedArea:'ContractedArea',
  payee:'Payee',
  openingBank:'OpeningBank',
  versionId:'VersionId',
  entry:'Entry',
  beforeSigning:'BeforeSigning',
  afterSigning:'AfterSigning',
  afterFetching:'AfterFetching',

  checkBookData: {
    checking: 'Checking...',
    complete: 'Complete',
    preparing: 'Preparing...'
  },
  languageCode: {
    en: 'English',
    zh: '中文'
  },
  useDate: 'Use Date',
  returnDate: 'Return Date',
  type: 'Type',
  isSideExpand:'Is Side Expand',
  tableId:'TableId',
  isPk:'IsPk',
  primaryKeyStrategy:'PrimaryKeyStrategy',
  workflowStatus: {
    0: 'Draft',
    1: 'Assign',
    2: 'Ongoing',
    3: 'Block',
    4: 'Waiting For Review',
    5: 'Done',
    6: 'Not approved',
    7: 'Approved',
    8: 'Self Evaluation',
    9: 'Work Handover',
    10: 'IT Confirm',
    11: 'Administration Confirm',
    12: 'HR Confirm',
    13: 'Finance Confirm',
    14: 'Audited',
    15:'Waiting for confirmation',
    16:'The data is wrong',
    17:'Pass',
    18:'Partially pass'
  },
  priorityCodes: {
    0: 'P0',
    1: 'P1',
    2: 'P2',
    3: 'P3'
  },
  actionCode: {
    assign: 'Assign',
    create: 'Create',
    restore: 'Restore',
    block: 'Block',
    edit: 'Edit',
    submit: 'Submit',
    copy: 'Copy',
    'review.refuse': 'Review refuse',
    'review.accept': 'Review accept',
    'approval.reject': 'Approval reject',
    'approval.agree': 'Approval agree',
    'conclusion.reject': 'Conclusion Reject',
    'conclusion.agree': 'Conclusion Agree',
    'last.agree': 'Agree',
    'last.disagree': 'Disagree',
    'disagree': 'Disagree',
    'agree': 'Agree',
    'score.agree': 'Approval agree',
    'score.return': 'Approval reject',
    'markError':'Mark Error',
    revocation: 'Revoke',
    confirm: 'Confirm',
    cashier: 'Cashier',
    receive: 'Receive',
    delete: 'Delete',
    print: 'Print',
    deliver: 'Deliver',
    encrypt: 'Encrypt',
    decrypt: 'Decrypt',
    open: 'Open self assessment',
    'review.pass':"Review Pass",
    'review.notPass':'Review Pass',
    'review.partPass':'Review Part Pass',
    'last.review.pass':"Review Pass",
    'last.review.notPass':'Review Part Pass',
    'last.review.partPass':'Review Pass',
    repossessionBuilding: 'RepossessionBuilding'
  },
  errorCode: {
    401: 'Unauthorized, please log in again',
    403: 'access denied',
    404: 'Sorry, the resource was not found!',
    500: 'Server internal error',
    504: 'network timeout',
    600: 'Incorrect parameters',
    601: 'Incorrect parameter value',
    700: 'No processing authority',
    701: 'Creation failed',
    702: 'Update failed',
    703: 'failed to delete',
    704: 'Processing failed',
    705: 'Please refresh and try again',
    706: 'No corresponding data found',
    707: 'The mailbox is not registered',
    708: 'token failure',
    750: 'Dictionary value already exists',
    751: 'Organizational structure already exists',
    752: 'System configuration already exists',
    800: 'Not logged in',
    801: 'Please enter the secondary password',
    802: 'Incorrect secondary password',
    803: 'Please set a secondary password',
    804: 'Secondary password already exists',
    805: 'Incorrect login password',
    806:'Two passwords cannot be the same',
    807:'Password rules are not met',
    808: 'Cannot be the same as the old password',
    809: 'The secondary password does not exist',
    810: 'Incorrect secondary password',
    900: 'The attendance scheduling configuration is incorrect',
    901: 'Please complete evaluation first',
    902: 'Insufficient working hours',
    903: 'Different schedules cannot be submitted at the same time',
    904: 'Wrong attendance type',
    5000: 'Workflow--not configured',
    5001: 'Workflow-incorrect configuration',
    5002: 'The action already exists',
    5003: 'Do not repeat visit',
    5101: 'Related people can\'t do it for themselves',
    5102: 'Related person cannot be superior',
    5201: 'The salary query field group is not configured',
  },
  menus: {
    userCenter: 'User Center',
    accountSettings: 'Account Settings',
    systemConfiguration: 'System Configuration',
    changePassword: 'Change Password',
    menuManagement: 'Menu Management',
    changeSecondaryPassword:'Change Secondary Password',
    setSecondaryPassword:'Set Secondary Password',
    resetSecondaryPassword:'Reset Secondary Password',
    newMessages: 'New Messages',
    baseSettings: 'Base Settings',
    securitySettings: 'Security Settings'
  },
  totalScoreAdminSuperior: 'Administrative Supervisor Rating',
  commentAdminSuperior: 'Comments from Administrative Superiors',
  totalScoreCompetencySuperior: 'Functional Superior Rating',
  commentCompetencySuperior: 'Functional Superior Comments',
  totalScoreRelated: 'Relevant Person Rating',
  commentRelated: 'Relevant Person Comments',
  calcCategory:'Calc Category',
  isBackfill:'Is Backfill',
  isEncrypt:'Is Encrypt',
  backfillProfileField:'Backfill ProfileField',
  fieldId:'FieldId',
  objectId:'ObjectId',
  nodeEntityId:'NodeEntityId',
  expandType:'ExpandType',
  role:'Role',
  numberCopies:'Number Copies',
  month:'Month',
  employees : 'Employees',
  parentCode:'Parent Code',
  whCode:'Wh Code',
  barCode:'Bar Code',
  maxCubage:'Max Cubage',
  maxWeight:'Max Weight',
  remark:'Remark',
  grade:'Grade',
  end:'End',
  hasChild:'Has Child',

  processCategroyId:'ProcessCategroyId',
  processRoleId:'ProcessRoleId',
  moduleId:'ModuleId',
  userId:'UserId',
  roleId:'RoleId',
  newrole:'Role management',
  associatedRole:'associatedRole',
  contractNumber:"contractNumber",
  categroy:'categroy',
  teamName:'teamName',
  projectManager:'projectManager',
  productManager:"productManager",
  testleader:'testleader',
  personChargeRelease:'personChargeRelease',
  softwareOwner:'softwareOwner',
  relatedProducts:"relatedProducts",
  project:"project",
  addSubmodule:'addSubmodule',
  headOfHardware:'headOfHardware',
  product:"product",
  appName:'appName',
  appVersion:"appName",
  resourceFileType:'resourceFileType',
  versionDescription:"versionDescription",
  activationTime:'activationTime',


}
